import { useStorage } from '@vueuse/core'
import type { RecentTasks } from '~/types/task.type'

export default function useRecentTasks() {
  const storage = useStorage<RecentTasks>('recent-tasks', {
    expanded: false,
    tasks: [],
  })

  return storage
}
