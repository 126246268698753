import type { EventInput } from '@fullcalendar/core'
import type { EventImpl } from '@fullcalendar/core/internal'
import type { TimeEntry } from './timeEntry.type'

export type CalendarView = 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth'

export type CalendarEventColor = 'red' | 'green' | 'blue' | 'orange' | 'yellow' | 'amber' | 'gray' | 'lime' | 'emerald' | 'teal' | 'sky' | 'violet' | 'pink'

export interface CalendarEventPropsTimeEntry {
  project: string
  timeEntry: TimeEntry<'withTask' | 'withProject' | 'withEmployee'>
  isVacation?: boolean
}

export interface CalendarEventPropsTimeEntry {
  project: string
  timeEntry: TimeEntry<'withTask' | 'withProject' | 'withEmployee'>
}

export interface CalendarEventPropsHoliday {
  holidayDurationHrs?: number
}

export interface CalendarEventImpl extends EventImpl {
  extendedProps: CalendarEventPropsTimeEntry
}

export interface HolidayEventInput extends EventInput {
  duration: number
}

export type CalendarEventProps = CalendarEventPropsTimeEntry | CalendarEventPropsHoliday

export type CalendarEventInput = EventInput & CalendarEventProps

export type CalendarEvent = CalendarEventInput & {
  extendedProps: CalendarEventProps
}

export interface CalendarEventDurations {
  dateToDuration: {
    [date: string]: number
  }
  sum: number
}

export const timeEntriesEventsSourceId = Symbol('timeEntries')
