import { useStorage } from '@vueuse/core'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'

export default function useLatestTimeEntry() {
  const storage = useStorage<{
    projectId?: Project['id']
    taskListId?: TaskList['id']
    taskId?: Task['id']
  }>('latest-time-entry', {})

  return storage
}
