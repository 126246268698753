import { addWeeks, subWeeks } from 'date-fns'
import type { TimeEntry } from '~/types/timeEntry.type'

export const useTimeEntries = defineStore('time-entries', () => {
  const user = useUserStore().user

  const timeEntriesFilter = ref<ApiTimeEntryGetListParams & { withEmployee: true }>({
    employeeId: user ? user.employee.id : 0,
    timeIntervalStartAt: subWeeks(Date.now(), 1),
    timeIntervalEndAt: addWeeks(Date.now(), 1),
    withEmployee: true,
  })

  const timeEntries = ref<TimeEntry<'withTask' | 'withProject' | 'withEmployee'>[]>([])

  const { data: timeEntriesData, refresh: fetchTimeEntries } = useApiTimeEntryGetList(timeEntriesFilter, { watch: false })

  watch(timeEntriesData, (timeEntriesData) => {
    timeEntries.value = timeEntriesData?.data || []
  })

  return { timeEntriesFilter, timeEntries, fetchTimeEntries }
})
