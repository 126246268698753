<script setup lang="ts">
import { differenceInMinutes } from 'date-fns'

const props = defineProps({
  startAt: {
    type: Number,
    required: true,
  },
  endAt: {
    type: Number,
    required: true,
  },
})

const duration = computed(() => {
  const minuteDifference = differenceInMinutes(props.endAt, props.startAt)
  const { hours, minutes } = minutesToHoursAndMinutesString(minuteDifference)

  if (minuteDifference >= 60)
    return `${hours} h ${minutes} min`
  else if (minuteDifference > 0)
    return `${minutes} min`
  else
    return '0 min'
})
</script>

<template>
  <span>Gesamt: <span class="font-bold">{{ duration }}</span></span>
</template>
